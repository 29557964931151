<template>
  <div>
    <slot name="header" />
    <div class="pb-12">
      <div class="container mx-auto pb-12 pt-12 max-w-md">
        <automated-advise-view
          :data="advisory"
          @checkoutActionClicked="checkout()"
          @doctorCommentActionClicked="next()"
          :options="data.viewOptions"
        />
      </div>
      <div
        class="max-w-sm mt-6 mx-auto"
        v-if="
          data.viewOptions.checkoutEnabled &&
            this.advisory &&
            data.viewOptions.manualCheckoutEnabled
        "
      >
        <div v-if="!this.checkoutComplete">
          <default-button
            @click.prevent.native="checkout()"
            primary
            :loading="submitting"
            class="w-full"
          >
            {{ data.viewOptions.checkoutButtonText }}
          </default-button>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          leave-active-class="transition ease-in duration-75"
          enter-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
          appear
        >
          <div
            class="max-w-sm mt-6 mx-auto text-l text-center"
            v-if="this.checkoutComplete"
          >
            <span> {{ data.viewOptions.checkoutReceipt }} </span>
          </div>
        </transition>
      </div>
      <div
        v-if="
          data.viewOptions.checkoutEnabled &&
            data.viewOptions.automaticCheckoutEnabled &&
            this.advisory &&
            this.checkoutComplete
        "
      >
        <div
          class="max-w-sm mt-6 mx-auto text-xl text-center"
          v-if="this.checkoutComplete"
        >
          <span> {{ data.viewOptions.checkoutReceipt }} </span>
        </div>
      </div>
      <slot
        :is-hidden="this.data.viewOptions.checkoutEnabled"
        :is-valid="this.advisory != null"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios"
import AutomatedAdviseView from "../AutomatedAdviseView.vue"

export default {
  components: {
    AutomatedAdviseView
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    slideFormData: {
      type: Array,
      default() {
        return []
      }
    },
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    next: {
      type: Function,
      default() {
        return () => { }
      }
    },
    setFormData: {
      type: Function,
      default() {
        return () => { }
      }
    },
    submit: {
      type: Function,
      default : () => {}
    }
  },

  data() {
    return {
      model: {
        doctorComment: null
      },
      complete: false,
      bufferComplete: false,
      fetchAttempts: 0,
      advised: false,
      advisory: null,
      checkoutComplete: false
    }
  },

  methods: {
    calculate() {
      axios.post("/api/reference/aggregate", {
        slideData: this.slideFormData,
        referenceCode: this.formData.referenceCode
      })
        .then(({ data }) => {
          this.updateFromResponseData(data)
          if (this.data.viewOptions.checkoutEnabled && this.data.viewOptions.automaticCheckoutEnabled) {
            this.checkout()
          }
        })
        .catch(error => {
          if (error.response && error.response.status !== 200 && this.fetchAttempts < 5) {
            this.fetchAttempts++
            setTimeout(() => this.calculate(), 1000 * this.fetchAttempts)
          }

          if (error.response && error.response.status !== 200 && this.fetchAttempts === 5) {
            this.advised = true
          }
        })
    },

    updateFromResponseData(data) {
      this.advisory = data.aggregate_aa
      this.advised = true
    },

    checkout() {
      this.checkoutComplete = false
      this.submit(false)
      this.checkoutComplete = true
    }
  },

  created() {
    setTimeout(() => {
      this.bufferComplete = true
    }, 5000)
    this.calculate()
  },




  computed: {
    answeredQuestions() {
      return this.slideFormData.filter(x => x.type !== "automaticAdvisorSlide"
        && x.type !== "orderSummarySlide" && x.value !== null && x.type !== "aav2")
    }
  }
}
</script>
